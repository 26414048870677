
import { defineComponent, PropType } from 'vue';
import { V1EntitiesAnalyticsFeedbackJohariItem } from '@/services/api/tsxass';

export default defineComponent({
  name: 'SimpleJohariWindow',

  props: {
    height: {
      type: [String, Number] as PropType<string | number>,
      default: 300,
    },
    values: {
      type: Array as PropType<V1EntitiesAnalyticsFeedbackJohariItem[]>,
      default: () => [],
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 5,
    },
    middleValueX: {
      type: Number,
      default: 7,
    },
    middleValueY: {
      type: Number,
      default: 7,
    },
  },

  computed: {
    cssVars(): Record<string, any> {
      return {
        '--chart-height': `${this.height}px`,
      };
    },
    axes() {
      return [
        {
          name: 'x',
          class: 'axis_x',
          label: this.$t('analytics.pdf.johari.selfRating'),
        },
        {
          name: 'y',
          class: 'axis_y',
          label: this.$t('analytics.pdf.johari.averageRating'),
        },
      ];
    },
    zones() {
      const getQuadrantPointsCount = (minX: number, maxX: number, minY: number, maxY: number) => {
        const filteredPoints = this.values.filter((point) => point.self >= minX
          && point.self <= maxX
          && point.avg >= minY
          && point.avg <= maxY);

        return filteredPoints.reduce((acc, item) => acc + item.count, 0);
      };

      return [
        {
          title: this.$t('analytics.pdf.johari.hiddenPotentialZone'),
          count: getQuadrantPointsCount(this.min, this.middleValueX, this.middleValueY + 0.01, this.max),
          color: 'tt-light-yellow',
        },
        {
          title: this.$t('analytics.pdf.johari.efficiencyZone'),
          count: getQuadrantPointsCount(this.middleValueX + 0.01, this.max, this.middleValueY + 0.01, this.max),
          color: 'tt-light-green',
        },
        {
          title: this.$t('analytics.pdf.johari.developmentZone'),
          count: getQuadrantPointsCount(this.min, this.middleValueX, this.min, this.middleValueY),
          color: 'tt-light-red',
        },
        {
          title: this.$t('analytics.pdf.johari.blindZone'),
          count: getQuadrantPointsCount(this.middleValueX + 0.01, this.max, this.min, this.middleValueY),
          color: 'tt-light-red',
        },
      ];
    },
    // Нет данных для отображения
    dataIsEmpty() {
      return this.zones.every((zone) => !zone.count);
    },
  },

  watch: {
    zones: {
      immediate: true,
      async handler() {
        await this.$nextTick();
        this.$emit('ready', { empty: this.dataIsEmpty });
      },
    },
  },
});
