
import { defineComponent } from 'vue';
import PdfAbstractRenderer from '@/components/analytics/pdf/renderers/PdfAbstractRenderer.vue';
import { tsxassApi } from '@/services/api';
import { V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion, V1EntitiesAnalyticsTemplatesSettingsZone } from '@/services/api/tsxass';
import PdfJohariZoneRenderer from './PdfJohariZoneRenderer.vue';

export default defineComponent({
  name: 'PdfJohariZonesRenderer',

  components: {
    PdfJohariZoneRenderer,
  },

  extends: PdfAbstractRenderer,

  props: {
    surveyeeName: {
      type: String,
      default: '',
    },
    pageNumber: {
      type: Number,
      default: 0,
    },
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      questions: [] as V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion[],
      minValue: 0,
      maxValue: 5,
      /**
       * Количество страниц в каждой зоне.
       * Заполняется в порядке рендеринга зон: рендерер зоны готов => записали для него кол-во страниц в массив.
       * Индекс - индекс зоны из zonesData.
       */
      zonePagesCount: [] as number[],
      dataIsLoaded: false,
      middleValueX: 7,
      middleValueY: 7,
      /**
       * Зоны с бэка для отрисовки иконок
       */
      zones: [] as V1EntitiesAnalyticsTemplatesSettingsZone[],
    };
  },

  computed: {
    zonesData() {
      const getQuadrantQuestions = (
        minX: number, maxX: number, minY: number, maxY: number, includeMinX = true, includeMinY = true,
      ) => {
        const filteredQuestions = this.questions.filter(
          (item) => {
            const self = Math.round(item.selfRating);
            const avg = Math.round(item.averageRating);
            return (includeMinX ? self >= minX : self > minX)
              && (self <= maxX)
              && (includeMinY ? avg >= minY : avg > minY)
              && avg <= maxY;
          },
        );

        return filteredQuestions;
      };

      return [
        {
          quadrantNumber: 3,
          name: this.$t('analytics.pdf.johari.developmentZone'),
          description: this.$t('analytics.pdf.johari.developmentZoneDescription'),
          questions: getQuadrantQuestions(
            this.minValue, this.middleValueX, this.minValue, this.middleValueY,
          ),
        },
        {
          quadrantNumber: 4,
          name: this.$t('analytics.pdf.johari.blindZone'),
          description: this.$t('analytics.pdf.johari.blindZoneDescription'),
          questions: getQuadrantQuestions(
            this.middleValueX, this.maxValue, this.minValue, this.middleValueY, false,
          ),
        },
        {
          quadrantNumber: 1,
          name: this.$t('analytics.pdf.johari.hiddenPotentialZone'),
          description: this.$t('analytics.pdf.johari.hiddenPotentialZoneDescription'),
          questions: getQuadrantQuestions(
            this.minValue, this.middleValueX, this.middleValueY, this.maxValue, true, false,
          ),
        },
        {
          quadrantNumber: 2,
          name: this.$t('analytics.pdf.johari.efficiencyZone'),
          description: this.$t('analytics.pdf.johari.efficiencyZoneDescription'),
          questions: getQuadrantQuestions(
            this.middleValueX, this.maxValue, this.middleValueY, this.maxValue, false, false,
          ),
        },
      ].filter((zone) => !!zone.questions.length);
    },
    zonesWithPageNumbers() {
      if (!this.zonesData.length) {
        return [];
      }

      return this.zonesData.map((zone, index) => {
        let pageNumber = undefined as undefined | number;
        if (!index) {
          // Номер страницы первой зоны - из пропса
          pageNumber = this.pageNumber;
        } else if (typeof this.zonePagesCount[index - 1] === 'number') {
          // Иначе - добавляем кол-во страниц из прошлых зон
          pageNumber = this.pageNumber + this.zonePagesCount.slice(0, index).reduce((a, b) => a + b, 0);
        }
        return {
          ...zone,
          pageNumber,
        };
      }).filter((zone) => zone.pageNumber !== undefined);
    },
  },

  watch: {
    dataIsLoaded() {
      if (this.dataIsLoaded && !this.zonesWithPageNumbers.length) {
        this.skipCurrentRenderer();
      }
    },
  },

  mounted() {
    this.loadQuestionsData();
  },

  methods: {
    async loadQuestionsData() {
      try {
        const {
          data: {
            questions,
            settings,
            state,
            analyticsTemplateSettings,
          },
        } = await tsxassApi.getV1AnalyticsFeedbackJohariQuestions(
          this.surveyId,
          undefined,
          this.userId || undefined,
        );
        this.questions = questions || [];
        this.minValue = settings.minValue;
        this.maxValue = settings.maxValue;
        const mid = (this.maxValue - this.minValue) / 2 + this.minValue;
        this.middleValueX = analyticsTemplateSettings.middleValueX || mid;
        this.middleValueY = analyticsTemplateSettings.middleValueY || mid;
        this.zones = analyticsTemplateSettings.zones || [];
        this.dataIsLoaded = true;
        this.belowAnonymityThreshold = !state?.display && state?.belowAnonymityThreshold;
      } catch (err) {
        this.onWidgetError();
      }
    },
    async onZoneReady(zoneIndex: number, { pagesCount }: { pagesCount: number }) {
      this.$set(this.zonePagesCount, zoneIndex, pagesCount);
      if (zoneIndex === this.zonesData.length - 1) {
        await this.$nextTick();
        // Рендерер готов тогда, когда отрисована последняя зона из zonesData
        this.onWidgetReady();
      }
    },
    onWidgetReady() {
      this.collectAndEmitImages();
    },
    onWidgetError() {
      this.$emit('error');
    },
  },
});
